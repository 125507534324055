body{
    background: #FEFEFE;
}
.MainSection{
    width: 100%;
    position: relative;
    top: -100px;
    z-index: 0;
    display:flex;
}

.ContentBox{
    width: 33.3333%;
    padding: 80px 30px 20px 30px;
    float: left;
    background: #f0f0f0;
    flex: 1;
}
.ContentBox:first-child{
    background: #f5f5f5;
}

.ContentBox:last-child{
    background: #f5f5f5;
}

.titleAbout{
    margin: 0 0 10px 0;
    width: 100%;
    font-family: 'Raleway', sans-serif;
    font-size: 1.7em;
    font-weight: 300;
    display: block;
    clear: both;
    position: relative;
}
.titleAbout:before {
    content: "";
    position: absolute;
    background: #199cdb;
    height: 2px;
    left: 0;
    top: -5px;
    width: 30px;
}

.ContentBox h3{
    color: #333;
    font-family: 'Raleway', sans-serif;
    font-weight: 200;
    font-size: 1.5em;
    margin: 0 0 10px 0;
}
.ContentBox p{
    margin: 0 0 20px 0;
    font-family: 'Raleway', sans-serif;
    font-size: 0.9em;
}
.ContentBox blockquote{
    margin: 0 0 40px 0;
    text-align:right;
    justify-content: right;
    font-family: 'Raleway', sans-serif;
    font-size: 0.9em;
}

.boxQuotationMark{
    display: block;
    float: left;
}
.iconQuotationMark{
    color: rgba(0, 0, 0, 0.3);
    font-size: 25px;
}

.BtDownloadCV{
    margin:20px 0 0 0;
    color:#199cdb;
    padding: 10px 35px;
    border: 2px solid #199cdb;
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
    border-radius: 90px;
    transition: 0.8s
}

.BtDownloadCV:hover{
    color: #FFF;
    background: #199cdb;
}

.personalInfo{
    list-style: none;

}
.personalInfo li{
    margin: 5px 0 15px 0;
}
.personalInfo .BolderTitle{
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
    color: #333;
}
.personalInfo span, .personalInfo a{
    font-family: 'Raleway', sans-serif;
}

.CursorBirthday, .CopyEmailLinkList{
    cursor: pointer;
    font-family: 'Raleway', sans-serif;
}

.TooltipCopyList{
    font-size: 0.7em;
    font-family: 'Raleway', sans-serif;
}

.linkWhatsApp, .linkSkype{
    text-decoration:none;
    color: #333;
}

.HiddenWhatsApp, .HiddenSkype, .HiddenBirthday{
    display: none;
}

.SkillBox{
    padding: 10px 0 5px 0;
}
.BoxIconSkill{
    width: 80px;
    float:left;
    text-align: center;
    padding: 0 10px;
}
.iconSkill{
    width:90%;
}
.textSkill{
    font-size: 1em;
    font-family: 'Raleway', sans-serif;
    border-bottom: 1px solid #DDD;
}
.textSkill h4{
    font-size: 1em;
    font-family: 'Raleway', sans-serif;
    color: #333;
}
.SkillBox:last-child .textSkill{
    border-bottom: none;
}

.ResumeSection{
    width: 90%;
    margin: 0 auto 0 auto;
    padding: 20px 0 20px 0;
    position: relative;
    top: -70px;
}
