
.ListIconSocial{
    margin: 15px auto;
    width: 100%;
    text-align: center;
}

.socialItems{
    padding: 15px;
    display: inline-block;
}

.linkSocial{
    width: 100%;
    height: 100%;
}

.iconSocial{
    width: 22px;
    height: 22px;
    font-size: 22px;
    transition: transform 1s;
    transform: scale(1);
    color: #333;
}

.iconSocial:focus, .iconSocial:hover{
    transition: transform 1s;
    transform: scale(1.5);
}