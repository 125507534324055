
header{
    flex: 1;
    min-height: 220px;
    justify-content: center ;
    align-items: center;
    flex-direction: row;
    background: rgb(150, 150, 150);
    margin-bottom: 100px;
    background: url(./../../assets/img/header-bg.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 10;
}

.header--profileImage{
    width:200px;
    border-radius: 100px;
    display: block;
    margin: 0 auto;
    border: 10px solid #FFF;
    position: relative;
    top: 90px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
    -ms-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.CopyEmailLink{
    background: none;
    color: #FFF;
    font-weight: bold;
    font-family: 'Gemunu Libre', sans-serif;
    font-size: 1em;
    cursor: pointer;
}

.TooltipCopy{
    position: absolute;
    width: 120px;
    top:55px;
    left: 80%;
    font-size: 0.7em;
    padding: 3px 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius:5px;
}

.header--EmailText{
    position: absolute;
    right: 100px;
    color: #EEE;
    font-family: 'Gemunu Libre', sans-serif;
    font-size: 1.2em;
    bottom: 10px;
}

.header--userName h1{
    font-size: 4em;
    font-family: 'Gemunu Libre', sans-serif;
    font-weight: 700;
    color: #555;
    text-align: center;
    margin: 0 auto;
    display: block;
}

.header--userProfession{
    font-size: 1.2em;
    font-family: 'Gemunu Libre', sans-serif;
    text-align: center;
    display: block;
}
