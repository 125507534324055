@import url(https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap);
@keyframes BlinkCursor {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 0; }
}
.BlinkCursor {
  animation: BlinkCursor .70s linear infinite;
}

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
}
body{
    background: #DDD;
}

.linkBack{
    position: absolute;
    top:10px;
    left:10px;
    font-size: 1.5em;
    transition: transform 1s;
    transform: scale(1);
    z-index: 20;
    color: #FFF;
}

.linkBack:hover{
    transition: transform 1s;
    transform: scale(1.5);
}

.iconBack{
    color: #FFF !important;
}

.container--menuBoxes{
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.linkBox{
    text-decoration: none;
    width: 30%;
    border: 1px solid #DDD;
    border-radius: 10px;
    padding: 15px;
    background: #EEE;
    box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
    transition: transform 1s;
    transform: scale(1);
}

.linkBox:hover{
    transition: transform 0.6s;
    transform: scale(1.1);
}
.itemBox h2{
    text-align: center;
    color: #555;
    font-family: 'Gemunu Libre', sans-serif;
    font-size: 1.2em;
}

@media (max-width: 768px)
{
    .container--menuBoxes{
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .linkBox{
        margin: 10px 0;
        width: 100%;
    }
}


@media (max-width: 1100px)
{
    .container--menuBoxes{
        width: 90% !important;
    }
}

header{
    flex: 1 1;
    min-height: 220px;
    justify-content: center ;
    align-items: center;
    flex-direction: row;
    background: rgb(150, 150, 150);
    margin-bottom: 100px;
    background: url(/static/media/header-bg.1932a539.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 10;
}

.header--profileImage{
    width:200px;
    border-radius: 100px;
    display: block;
    margin: 0 auto;
    border: 10px solid #FFF;
    position: relative;
    top: 90px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
    -ms-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.CopyEmailLink{
    background: none;
    color: #FFF;
    font-weight: bold;
    font-family: 'Gemunu Libre', sans-serif;
    font-size: 1em;
    cursor: pointer;
}

.TooltipCopy{
    position: absolute;
    width: 120px;
    top:55px;
    left: 80%;
    font-size: 0.7em;
    padding: 3px 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius:5px;
}

.header--EmailText{
    position: absolute;
    right: 100px;
    color: #EEE;
    font-family: 'Gemunu Libre', sans-serif;
    font-size: 1.2em;
    bottom: 10px;
}

.header--userName h1{
    font-size: 4em;
    font-family: 'Gemunu Libre', sans-serif;
    font-weight: 700;
    color: #555;
    text-align: center;
    margin: 0 auto;
    display: block;
}

.header--userProfession{
    font-size: 1.2em;
    font-family: 'Gemunu Libre', sans-serif;
    text-align: center;
    display: block;
}


.ListIconSocial{
    margin: 15px auto;
    width: 100%;
    text-align: center;
}

.socialItems{
    padding: 15px;
    display: inline-block;
}

.linkSocial{
    width: 100%;
    height: 100%;
}

.iconSocial{
    width: 22px;
    height: 22px;
    font-size: 22px;
    transition: transform 1s;
    transform: scale(1);
    color: #333;
}

.iconSocial:focus, .iconSocial:hover{
    transition: transform 1s;
    transform: scale(1.5);
}
body{
    background: #FEFEFE;
}
.MainSection{
    width: 100%;
    position: relative;
    top: -100px;
    z-index: 0;
    display:flex;
}

.ContentBox{
    width: 33.3333%;
    padding: 80px 30px 20px 30px;
    float: left;
    background: #f0f0f0;
    flex: 1 1;
}
.ContentBox:first-child{
    background: #f5f5f5;
}

.ContentBox:last-child{
    background: #f5f5f5;
}

.titleAbout{
    margin: 0 0 10px 0;
    width: 100%;
    font-family: 'Raleway', sans-serif;
    font-size: 1.7em;
    font-weight: 300;
    display: block;
    clear: both;
    position: relative;
}
.titleAbout:before {
    content: "";
    position: absolute;
    background: #199cdb;
    height: 2px;
    left: 0;
    top: -5px;
    width: 30px;
}

.ContentBox h3{
    color: #333;
    font-family: 'Raleway', sans-serif;
    font-weight: 200;
    font-size: 1.5em;
    margin: 0 0 10px 0;
}
.ContentBox p{
    margin: 0 0 20px 0;
    font-family: 'Raleway', sans-serif;
    font-size: 0.9em;
}
.ContentBox blockquote{
    margin: 0 0 40px 0;
    text-align:right;
    justify-content: right;
    font-family: 'Raleway', sans-serif;
    font-size: 0.9em;
}

.boxQuotationMark{
    display: block;
    float: left;
}
.iconQuotationMark{
    color: rgba(0, 0, 0, 0.3);
    font-size: 25px;
}

.BtDownloadCV{
    margin:20px 0 0 0;
    color:#199cdb;
    padding: 10px 35px;
    border: 2px solid #199cdb;
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
    border-radius: 90px;
    transition: 0.8s
}

.BtDownloadCV:hover{
    color: #FFF;
    background: #199cdb;
}

.personalInfo{
    list-style: none;

}
.personalInfo li{
    margin: 5px 0 15px 0;
}
.personalInfo .BolderTitle{
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
    color: #333;
}
.personalInfo span, .personalInfo a{
    font-family: 'Raleway', sans-serif;
}

.CursorBirthday, .CopyEmailLinkList{
    cursor: pointer;
    font-family: 'Raleway', sans-serif;
}

.TooltipCopyList{
    font-size: 0.7em;
    font-family: 'Raleway', sans-serif;
}

.linkWhatsApp, .linkSkype{
    text-decoration:none;
    color: #333;
}

.HiddenWhatsApp, .HiddenSkype, .HiddenBirthday{
    display: none;
}

.SkillBox{
    padding: 10px 0 5px 0;
}
.BoxIconSkill{
    width: 80px;
    float:left;
    text-align: center;
    padding: 0 10px;
}
.iconSkill{
    width:90%;
}
.textSkill{
    font-size: 1em;
    font-family: 'Raleway', sans-serif;
    border-bottom: 1px solid #DDD;
}
.textSkill h4{
    font-size: 1em;
    font-family: 'Raleway', sans-serif;
    color: #333;
}
.SkillBox:last-child .textSkill{
    border-bottom: none;
}

.ResumeSection{
    width: 90%;
    margin: 0 auto 0 auto;
    padding: 20px 0 20px 0;
    position: relative;
    top: -70px;
}

.my-masonry-grid { 
    display: flex;
    margin-left: -30px; 
    width: auto;
    margin-top: 50px;
}
.my-masonry-grid_column {
    padding-left: 30px; 
    background-clip: padding-box;
}
  
.my-masonry-grid_column > div { 
    background: #FFF;
    margin-bottom: 30px;
    border: 1px solid #DDD;
    padding: 40px 20px;
}

@media (max-width: 800px) {
    .my-masonry-grid {
      margin-left: -15px; 
    }
    .my-masonry-grid_column {
      padding-left: 15px; 
    }
    .my-masonry-grid_column > div {
      margin-bottom: 15px; 
    }
}

.my-masonry-grid_column > div .titleAbout{
    width: 70%;
    float: left;
}
.iconResume{
    width: 30%;
    padding: 0 20px;
}

.ProgressBarSkill div{
    margin-bottom: 10px;
}

.ContentSkills p{
    font-family: 'Raleway', sans-serif;
}

.ListInterests{
    list-style: none;
    margin: 20px 0;
}
.ListInterests li{
    display: inline-block;
    width: 25%;
    text-align: center;
}
.ListInterests li img{
    width: 100%;
    padding: 10px;
    -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	-webkit-filter: gray;
	        filter: gray; /* IE */
    cursor: pointer;
    transition: 0.8s;
}
.ListInterests li img:hover{
    -webkit-filter: grayscale(0%);
    filter: grayscale(0);
	-webkit-filter: none;
	        filter: none; /* IE */
}

.InterestsLabel{
    font-family: 'Raleway', sans-serif;
}

.ckDfJz{display: none;}
.YVQEY::before{
    content: 'Desde';
    font-size: 0.8em;
    color: #333;
    font-style: italic;
    font-family: 'Raleway', sans-serif;
}
.huvypy{
    font-size: 0.8em;
    font-family: 'Raleway', sans-serif;
}
.YVQEZ, .YVQEY{
    min-width: auto !important;
}


.iconBack{
    width: 15px;
    color: #555;
}







