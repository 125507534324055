@import url('https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

@keyframes BlinkCursor {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 0; }
}
.BlinkCursor {
  animation: BlinkCursor .70s linear infinite;
}

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
}
body{
    background: #DDD;
}

.linkBack{
    position: absolute;
    top:10px;
    left:10px;
    font-size: 1.5em;
    transition: transform 1s;
    transform: scale(1);
    z-index: 20;
    color: #FFF;
}

.linkBack:hover{
    transition: transform 1s;
    transform: scale(1.5);
}

.iconBack{
    color: #FFF !important;
}

.container--menuBoxes{
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.linkBox{
    text-decoration: none;
    width: 30%;
    border: 1px solid #DDD;
    border-radius: 10px;
    padding: 15px;
    background: #EEE;
    box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
    transition: transform 1s;
    transform: scale(1);
}

.linkBox:hover{
    transition: transform 0.6s;
    transform: scale(1.1);
}
.itemBox h2{
    text-align: center;
    color: #555;
    font-family: 'Gemunu Libre', sans-serif;
    font-size: 1.2em;
}

@media (max-width: 768px)
{
    .container--menuBoxes{
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .linkBox{
        margin: 10px 0;
        width: 100%;
    }
}


@media (max-width: 1100px)
{
    .container--menuBoxes{
        width: 90% !important;
    }
}