.my-masonry-grid {
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex;
    margin-left: -30px; 
    width: auto;
    margin-top: 50px;
}
.my-masonry-grid_column {
    padding-left: 30px; 
    background-clip: padding-box;
}
  
.my-masonry-grid_column > div { 
    background: #FFF;
    margin-bottom: 30px;
    border: 1px solid #DDD;
    padding: 40px 20px;
}

@media (max-width: 800px) {
    .my-masonry-grid {
      margin-left: -15px; 
    }
    .my-masonry-grid_column {
      padding-left: 15px; 
    }
    .my-masonry-grid_column > div {
      margin-bottom: 15px; 
    }
}

.my-masonry-grid_column > div .titleAbout{
    width: 70%;
    float: left;
}
.iconResume{
    width: 30%;
    padding: 0 20px;
}

.ProgressBarSkill div{
    margin-bottom: 10px;
}

.ContentSkills p{
    font-family: 'Raleway', sans-serif;
}

.ListInterests{
    list-style: none;
    margin: 20px 0;
}
.ListInterests li{
    display: inline-block;
    width: 25%;
    text-align: center;
}
.ListInterests li img{
    width: 100%;
    padding: 10px;
    -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: gray; /* IE */
    cursor: pointer;
    transition: 0.8s;
}
.ListInterests li img:hover{
    -webkit-filter: grayscale(0%);
    filter: grayscale(0);
	filter: none; /* IE */
}

.InterestsLabel{
    font-family: 'Raleway', sans-serif;
}

.ckDfJz{display: none;}
.YVQEY::before{
    content: 'Desde';
    font-size: 0.8em;
    color: #333;
    font-style: italic;
    font-family: 'Raleway', sans-serif;
}
.huvypy{
    font-size: 0.8em;
    font-family: 'Raleway', sans-serif;
}
.YVQEZ, .YVQEY{
    min-width: auto !important;
}
